import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import HeroProductSection from "../components/HeroProductSection";
import AccordionUpdate from "../components/AccordionUpdate";
import TrustpilotSection from "../components/TrustpilotSection";
import CtaSection from "../components/CtaSection";
import CalculatorBorrowSimpleUpdate from "../components/CalculatorBorrowSimpleUpdate";
import RowFeaturedBlocks from "../components/RowFeaturedBlocks";
import MoreProductsSection from "../components/MoreProductsSection";
import ListTextCtaSection from "../components/ListTextCtaSection";
import ListImageCtaSection from "../components/ListImageCtaSection";
import CtaImageSection from "../components/CtaImageSection";

export default function HeroProduct({ data }) {
	const {
		heroTitle,
		heroText,
		heroCtaFirst,
		heroImage,
		heroImageLink,
		heroVideo,
		faqsTitle,
		faqsItems,
		ctaImageSection,
		calculatorDisclaimer,
		testimonials,
		ctaSection,
		listTextCta,
		listTextCtaAlternative,
		listImageCta,
		featuredBlocks,
		moreProductsSection,
		metaTitle,
		metaDescription,
		metaShareImage,
	} = data.craft.entry ?? [];


	const ctaSectionData = ctaSection?.[0];
	const featuredBlocksData = featuredBlocks?.[0];
	const listTextCtaData = listTextCta?.[0];
	const listTextCtaAlternativeData = listTextCtaAlternative?.[0];
	const listImageCtaData = listImageCta?.[0];
	const ctaImageSectionData = ctaImageSection?.[0];

	return (
		<Layout className="layout--heroProduct">
			<SEO
				title={metaTitle}
				description={metaDescription}
				image={metaShareImage}
			/>

			<HeroProductSection
				title={heroTitle}
				text={heroText}
				image={heroImage}
				video={heroVideo}
				imageLink={heroImageLink}
				cta={heroCtaFirst}
				colorTheme="purple"
				nextTheme="dark"
				mobileTheme="purple"
			/>

			<RowFeaturedBlocks
				colorTheme="dark"
				data={featuredBlocksData}
			/>

			{ /* For anchor links */ }
			<div id="calculator" style={{
				marginTop: '-8rem',
				marginBottom: '8rem'
   		}} />

			<CalculatorBorrowSimpleUpdate
				colorTheme="dark"
				nextTheme="light"
				disclaimer={calculatorDisclaimer}
			/>


			<CtaImageSection
				colorTheme="dark"
				data={ctaImageSectionData}
			/>

			<ListTextCtaSection
				data={listTextCtaData}
			/>

			<AccordionUpdate title={faqsTitle} items={faqsItems} colorTheme="dark" />

			<ListTextCtaSection
				colorTheme="dark"
				background="off-purple"
				data={listTextCtaAlternativeData}
			/>

			<TrustpilotSection
				data={testimonials}
				colorTheme="dark"
			/>

			<ListImageCtaSection
				colorTheme="dark"
				data={listImageCtaData}
			/>

			<CtaSection
				noPadding={true}
				colorTheme="dark"
				data={ctaSectionData}
			/>

			<MoreProductsSection
				colorTheme="dark"
				data={moreProductsSection}
			/>
		</Layout>
	);
}

export const pageQuery = graphql`
	query veicleFinance($id: CraftCMS_QueryArgument!) {
		craft {
			entry(id: [$id]) {
				slug
				... on CraftCMS_heroProducts_heroProducts_Entry {
					id
					heroTitle
					heroText
					heroCtaFirst {
						element {
							slug
							uri
						}
						ariaLabel
						customText
						target
						text
						title
						type
						url
					}
					heroVideo {
						id
						url
						title
					}
					heroImage {
						width
						height
						url
						title
						imagerX(transform: "maxWidth-1920") {
							width
							height
							url
						}
						imagerXWp: imagerX(transform: "maxWidth-1920-wp") {
							width
							height
							url
						}
					}
					heroImageLink {
						element {
							slug
							uri
						}
						ariaLabel
						customText
						target
						text
						title
						type
						url
					}
					calculatorDisclaimer
					faqsTitle
					faqsItems {
						... on CraftCMS_faqsItems_faqsItem_BlockType {
							id
							faqsItemTitle
							faqsItemText
						}
					}
					testimonials {
						... on CraftCMS_testimonials_BlockType {
							backgroundImage {
								title
								width
								height
								url
								imagerX(transform: "maxWidth-1920") {
									width
									height
									url
								}
								imagerXWp: imagerX(transform: "maxWidth-1920-wp") {
									width
									height
									url
								}
							}
							testimonials {
								... on CraftCMS_testimonials_default_Entry {
									date
									title
									richtext
									cite
								}
							}
						}
					}
					listTextCta {
						... on CraftCMS_listTextCta_BlockType {
							text
							heading
							button {
								ariaLabel
								customText
								element {
									slug
									uri
								}
								target
								text
								title
								type
								url
							}
						}
					}
					listTextCtaAlternative {
						... on CraftCMS_listTextCtaAlternative_BlockType {
							text
							heading
							button {
								ariaLabel
								customText
								element {
									slug
									uri
								}
								target
								text
								title
								type
								url
							}
						}
					}
					listImageCta {
						... on CraftCMS_listImageCta_BlockType {
							heading
							text
							image {
								title
								width
								height
								url
								imagerX(transform: "maxWidth-1920") {
									width
									height
									url
								}
								imagerXWp: imagerX(transform: "maxWidth-1920-wp") {
									width
									height
									url
								}
							}
							button {
								ariaLabel
								customText
								element {
									slug
									uri
								}
								target
								text
								title
								type
								url
							}
						}
					}
					ctaImageSection {
						... on CraftCMS_ctaImageSection_BlockType {
							heading
							text
							highlight
							image {
								width
								height
								url
								title
								imagerX(transform: "maxWidth-2560") {
									width
									height
									url
								}
								imagerXWp: imagerX(transform: "maxWidth-2560-wp") {
									width
									height
									url
								}
							}
							button {
								element {
									slug
									uri
								}
								ariaLabel
								customText
								target
								text
								title
								type
								url
							}
						}
					}
					featuredBlocks {
						... on CraftCMS_featuredBlocks_BlockType {
							heading
							button {
								ariaLabel
								customText
								element {
									slug
									uri
								}
								target
								text
								title
								type
								url
							}
							blocks {
								... on CraftCMS_blocks_content_BlockType {
									background
									heading
									text
									modalLink {
										element {
											slug
											uri
										}
										ariaLabel
										customText
										target
										text
										title
										type
										url
									}
								}
							}
						}
					}
          featuredSlider {
						... on CraftCMS_featuredSlider_BlockType {
							subHeading
							text
							heading
							highlightText
							backgroundImage {
								width
								height
								url
								title
								imagerX(transform: "maxWidth-2560") {
									width
									height
									url
								}
								imagerXWp: imagerX(transform: "maxWidth-2560-wp") {
									width
									height
									url
								}
							}
							button {
								element {
									slug
									uri
								}
								ariaLabel
								customText
								target
								text
								title
								type
								url
							}
						}
					}
					ctaSection {
						... on CraftCMS_ctaSection_BlockType {
							id
							heading
							animation
							text
							theme(label: false)
							button {
								ariaLabel
								customText
								element {
									slug
									uri
								}
								target
								text
								title
								type
								url
							}
						}
					}
					moreProductsSection {
						... on CraftCMS_moreProductsSection_BlockType {
							heading
							products {
								... on CraftCMS_products_product_BlockType {
									animation
									background
									text
									highlight
									product {
										title
										uri
										url
										slug
									}
								}
							}
						}
					}
					metaTitle
					metaDescription
					metaShareImage {
						filename
						url
					}
				}
			}
		}
	}
`;
